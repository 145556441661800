<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card :title="'1. 일반정보 - ' + data.mdmChemMaterialName" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn 
                  v-if="data.mdmChemMaterialId && editable"
                  label="출력" 
                  icon="print"
                  @btnClicked="printChem" /> -->
                <!-- <c-btn v-if="data.mdmChemMaterialId && editable" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" /> -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="false"
                  label="CAS No."
                  name="casNo"
                  v-model="data.casNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="false"
                  label="어는점"
                  suffix="℃"
                  name="freezingPoint"
                  v-model="data.freezingPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="false"
                  label="끓는점"
                  suffix="℃"
                  name="boilPoint"
                  v-model="data.boilPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="false"
                  label="인화점"
                  suffix="℃"
                  name="flashPoint"
                  v-model="data.flashPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  label="주요용도"
                  name="mainUse"
                  :rows="2"
                  v-model="data.mainUse">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-table
            ref="grid"
            title="2. 물질정보"
            tableId="grid"
            :columns="grid.columns"
            :data="data.chems"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :gridHeightAuto="true"
            :usePaging="false"
            :hideBottom="true"
            :isExcelDown="false"
          >
          </c-table>
          <c-card title="3. 그림문자" class="cardClassDetailForm msdsc pmsds" :collapsed="true">
            <template slot="card-detail">
              <q-intersection
                v-for="(item, index) in imgList"
                :key="index"
                class="example-item"
                style="display:inline;"
              >
                <img :src="require('@/assets/images/'+item.imgname+'.gif')"
                  :style="{ width: '90px', }" :class="[!item.chk ? 'noselectImg' : '']">
                <q-checkbox v-model="item.chk" color="red" />
              </q-intersection>
            </template>
          </c-card>
          <c-card title="4. 유해위험 문구" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  label=""
                  name="fourTxt"
                  :rows="6"
                  v-model="data.fourTxt">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="5. 응급조치요령" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="가. 눈에 들어갔을 때"
                  name="fourFirst"
                  :rows="5"
                  v-model="data.fiveFirst">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="나. 피부에 접촉했을 때"
                  name="fiveSecond"
                  :rows="5"
                  v-model="data.fiveSecond">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="다. 흡입했을 때"
                  name="fiveThird"
                  :rows="5"
                  v-model="data.fiveThird">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="라. 먹었을 때"
                  name="fiveFourth"
                  :rows="5"
                  v-model="data.fiveFourth">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="마. 기타"
                  name="fiveFive"
                  :rows="5"
                  v-model="data.fiveFive">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="6. 저장방법" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  label=""
                  name="sixTxt"
                  :rows="6"
                  v-model="data.sixTxt">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="7. 피해야 할 조건 및 물질" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  label="피해야 할 조건"
                  name="sevenFirst"
                  v-model="data.sevenFirst">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  label="피해야 할 물질"
                  name="sevenSecond"
                  v-model="data.sevenSecond">
                </c-text>
              </div>
            </template>
          </c-card>
          <c-card title="8. 누출 및 폭발·화재 사고시 대처방법" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  label="누출"
                  name="eightTxt"
                  :rows="5"
                  v-model="data.eightTxt">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-table
            ref="grid2"
            title="9. 법적규제현황"
            tableId="grid2"
            :columns="grid2.columns"
            :data="data.reguls"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :gridHeightAuto="true"
            :usePaging="false"
            :hideBottom="true"
            :isExcelDown="false"
            :hideHeader="true"
            :merge="grid2.merge"
          >
          </c-table>
          <c-card title="10. 취급시 주의사항" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  label=""
                  name="tenTxt"
                  :rows="5"
                  v-model="data.tenTxt">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemProcess-knowhow-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        usageCd: '',  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        msdsNo: '',  // MSDS번호
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고 환산계수
        inConversonUnitCd: null,  // 입고 환산단위
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '',  // 미사용사유
        preMdmChemMaterialId: '',  // 이전화학자재 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '물질명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            sortable: false,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함유량(%)',
            align: 'center',
            sortable: false,
          },
        ],
      },
      grid2: {
        merge: [
          { index: 0, colName: 'regulationBillName' },
        ],
        columns: [
          {
            name: 'regulationBillName',
            field: 'regulationBillName',
            label: '',
            align: 'left',
            sortable: false,
          },
          {
            name: 'regulatedItemName',
            field: 'regulatedItemName',
            label: '',
            align: 'left',
            sortable: false,
          },
        ],
      },
      imgList: [
        {
          imgname: 'SYM01',
          chk: false,
        },
        {
          imgname: 'SYM02',
          chk: false,
        },
        {
          imgname: 'SYM03',
          chk: false,
        },
        {
          imgname: 'SYM04',
          chk: false,
        },
        {
          imgname: 'SYM05',
          chk: false,
        },
        {
          imgname: 'SYM06',
          chk: false,
        },
        {
          imgname: 'SYM07',
          chk: false,
        },
        {
          imgname: 'SYM08',
          chk: false,
        },
        {
          imgname: 'SYM09',
          chk: false,
        },
      ],
      editable: true,
      saveUrl: transactionConfig.chm.summary.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      isdelete: false,
      data: {
        mdmChemMaterialId: '',  // 화학자재 일련코드
        mainUse: '',  // 주요용도
        casNo: '',  // casNo
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        imageWord: '',  // 그림문자
        fourTxt: '',  // 유해위험 문구
        fiveFirst: '',  // 응급조치요령-눈에들어갔을때
        fiveSecond: '',  // 응급조치요령-피부에접촉
        fiveThird: '',  // 응급조치요령-흡입
        fiveFourth: '',  // 응급조치요령-먹었을때
        fiveFive: '',  // 응급조치요령-기타
        sixTxt: '',  // 저장방법
        sevenFirst: '',  // 피해야할 조건
        sevenSecond: '',  // 피해야할 물질
        eightTxt: '',  // 누출대처방법
        tenTxt: '',  // 취급시 주의사항
        chems: [],  // 구성물질
        reguls: [], // 규제항목
      },
      printUrl: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.chm.summary.get.url;
      this.printUrl = selectConfig.chm.summary.print.url;
      this.insertUrl = transactionConfig.chm.summary.insert.url;
      this.updateUrl = transactionConfig.chm.summary.update.url;
      this.deleteUrl = transactionConfig.chm.summary.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmChemMaterialId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId};
        this.$http.request((_result) => {
          if (_result.data) {
            this.data = _result.data;
            if (_result.data.imageWord) {
              let imagewords = _result.data.imageWord.split(',');
              if (imagewords.length > 0) {
                this.$_.forEach(this.imgList, item => {
                  this.$_.forEach(imagewords, _img => {
                    if (item.imgname == _img) {
                      item.chk = true;
                    }
                  });
                });
              }
            }
          }
        },);
      }
    },
    saveData() {
      if (this.data.mdmChemMaterialId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.mdmChemMaterialId = this.popupParam.mdmChemMaterialId;
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              let imagewords = [];
              this.$_.forEach(this.imgList, item => {
                if (item.chk) {
                  imagewords.push(item.imgname);
                }
              });
              this.data.imageWord = imagewords.join(',');

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.mdmChemMaterialId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    printChem() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.data.mdmChemMaterialId);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.data.materialName + ' MSDS요약본' + ".docx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
  },
};
</script>
<style lang="sass">
.msdsc
  margin-top: 10px
.pmsds > .cardheader
  padding-top: 0px !important
  padding-bottom: 10px !important
.example-item .q-checkbox .q-checkbox__inner
  margin: auto !important
.example-item .inline
    display: block !important
.noselectImg
  opacity: 0.2
</style>